import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import get from 'lodash/get'
import { isEmpty } from 'ramda'

// TODO: CLEAN THIS SHIT AND SAGAS
import moment from 'moment'

const qrImage = '/PlaceholderQR.png'

const { Types, Creators } = createActions({
  deviceRequest: null,
  deviceSuccess: ['devices'],
  deviceRequestRefresh: null,
  deviceRefreshSuccess: ['devices'],
  deviceRefreshFailure: null,
  deviceCurrent: ['currentDevice'],
  deviceRequestCount: null,
  deviceRequestOnline: null,
  deviceRequestDisconnected: null,
  deviceRequestCounter: ['data'],
  deviceSuccessCounter: ['data'],
  deviceRequestCounterOnline: ['data'],
  deviceSuccessCounterOnline: ['data'],
  deviceSuccessCounterDisconnected: ['data'],
  deviceRequestCounterDisconnected: ['data'],
  deviceRequestBattery: null,
  deviceSuccessBattery: ['data', 'params'],
  deviceRequestCycle: null,
  deviceSuccessCycle: ['data', 'params'],
  deviceRequestAverage: null,
  deviceSuccessAverage: ['data', 'params'],
  deviceRemove: null,
  deviceRequestGateways: ['pageNumber', 'pageSize', 'deviceType'],
  deviceResetRequestGateways: ['pageNumber', 'pageSize', 'deviceType'],
  deviceSuccessGateways: ['devices'],
  deviceGenericStop: null,
  deviceFailure: null,
  deviceSetRequest: ['currentDevice'],
  deviceSetGateway: ['currentDevice'],
  deviceGetTelemetry: ['id'],
  deviceSuccessTelemetry: ['telemetryData'],
  deviceClearCurrent: null,
  deviceToggleMultiple: ['id'],
  deviceResetMultiple: null,
  deviceSetQrData: ['name', 'value'],
  deviceRequestBatteries: null,
  deviceUpdateRequest: ['data'],
  deviceUpdateSuccess: null,
  deviceUpdateFailure: null,
  deviceSelectSite: ['siteId'],
  deviceUploadProvisionRequest: ['data', 'site'],
  deviceUploadProvisionRequestSuccess: ['data'],
  deviceUploadProvisionRequestFailure: ['data'],
  deviceUploadProvisionClean: null,
  deviceFamiliesAndModelsRequest: null,
  deviceFamiliesAndModelsRequestSuccess: ['data'],
  deviceFamiliesAndModelsRequestFailure: null,
  devicePrinterMediaStatusRequest: ['id'],
  devicePrinterMediaStatusSuccess: ['data'],
  devicePrinterMediaStatusFailure: null,
  devicePrinterRibbonStatusRequest: ['id'],
  devicePrinterRibbonStatusSuccess: ['data'],
  devicePrinterRibbonStatusFailure: null,
  deviceUpdateAliasRequest: ['data'],
  deviceUpdateAliasSuccess: ['data'],
  deviceUpdateAliasFailure: null,
  deviceBackgroundCancelAll: null,
  deviceBackgroundRequestAll: ['pageNumber', 'pageSize', 'deviceType', 'singlePage'],
  deviceSetRemoteAccess: ['selectedRemoteAccessDevice', 'selectedRemoteAccessDeviceLoading'],
  deviceSetApplicationReportsLoading: null,
  deviceQrRequest: ['data'],
  deviceQrSuccess: ['data'],
  deviceQrFailure: ['status'],
  deviceQrReset: null,
  updateBulkEditData: ['data'],
  updateBulkEditDataRequest: ['data'],
  updateBulkEditDataRequestSuccess: ['data'],
  updateBulkEditDataRequestFailure: null,
  deviceSetFilterDevicesById: ['filterDevicesById'],
  deviceSiteFilter: ['siteId'],
  deviceSiteFilterSuccess: ['devices'],
  deviceSiteFilterFailure: null,
  deviceRemoveFilterDevicesById: null,
  deviceSendRaCommandAction: ['deviceId', 'cmdStr'],
  devicesOutOfRangeData: ['data'],
  devicesOutOfRangeRequest: ['urlParams','pageSize', 'pageNumber'],
  devicesOutOfRangeSuccess: ['data'],
  devicesOutOfRangeGenericResponse: null,
  devicesOutOfRangeAdditionalRequest: ['urlParams','pageSize', 'pageNumber'],
  devicesOutOfRangeAdditionalSuccess: ['data'],
  devicesOutOfRangeClean: null,
  setStatusConnection: ['status', 'serial']
})

export const DeviceTypes = Types
export default Creators

export const INITIAL_STATE = Immutable({
  devicesbyId: [],
  devices: {},
  totalDevices: 0,
  stopLoading: false,
  currentDevice: '',
  selectedRemoteAccessDevice: '',
  selectedRemoteAccessDeviceLoading: false,
  telemetryData: {},
  loading: false,
  loadingBackground: false,
  reloading: false,
  isDeviceAliasReadonly: false,
  isAliasSetIncorrectly: false,
  errors: [],
  counters: {
    mobilecomputer: {
      all: 0,
      connected: 0,
      loading: false
    },
    printer: {
      all: 0,
      connected: 0,
      loading: false
    },
    scanner: {
      all: 0,
      connected: 0,
      loading: false
    },
    gateway: {
      all: 0,
      connected: 0,
      loading: false
    }
  },
  selectedDevices: [],
  qrData: {
    Site: '',
    isValid: false,
    acceptedTerms: false,
    loading: false,
    error: '',
    qrValue: '',
    imageUrl: qrImage,
    imageUrls: [],
  },
  selectedSite: '',
  devicesToProvision: [],
  devicesBulkEdit:
    [
      /*   { serial: { value: 'EDA5118316B2018', error: '' }, alias: ' myalias', siteId:{ value: '5bedbc8bac13a010482f59e0', error: '' }, site: 'my site' },
         { serial: { value: 'EDA5118316B2018', error: '' }, alias: ' myalias', siteId:{ value: '5bedbc8bac13a010482f59e0', error: '' }, site: 'my site' },
         { serial: { value: 'EDA5118316B2018', error: '' }, alias: ' myalias', siteId:{ value: '5bedbc8bac13a010482f59e0', error: '' }, site: 'my site' },
         { serial: { value: 'EDA5118316B2018', error: '' }, alias: ' myalias', siteId:{ value: '5bca1b893d94ea5518e018ad', error: '' }, site: 'my site' },
         { serial: { value: 'EDA5118316B2018', error: '' }, alias: ' myalias', siteId:{ value: '5bca1b893d94ea5518e018ad', error: '' }, site: 'my site' },
         { serial: { value: '18333B2460', error: '' }, alias: ' myalias', siteId:{ value: '5bca1b893d94ea5518e018ad', error: '' }, site: 'my site' },
         { serial: { value: '18333B2460', error: '' }, alias: ' myalias', siteId:{ value: '5bca1b893d94ea5518e018ad', error: '' }, site: 'my site' },
         { serial: { value: '18333B2460', error: '' }, alias: ' myalias', siteId:{ value: '5bca1b893d94ea5518e018ad', error: '' }, site: 'my site' },
         { serial: { value: '18333B2460', error: '' }, alias: ' myalias', siteId:{ value: '5bca1b893d94ea5518e018ad', error: '' }, site: 'my site' },
         { serial: { value: '18333B2460', error: '' }, alias: ' myalias', siteId:{ value: '5bca1b893d94ea5518e018ad', error: '' }, site: 'my site' } */
    ],
  devicesOutOfRange: [],
  families: {},
  events: {
    isPrinterOutOfRibbon: null,
    isPrinterOutOfMedia: null
  },
  sending: false,
  applicationReports: [],
  applicationReportsLoading: false,
  appData: [],
  filterDevicesById: []
})

const insertDevicesId = (state, { devices }) => {
  return (devices || []).reduce((arr, d) => {
    if (arr.indexOf(d.serialNumber) === -1 && d.deviceType) {
      return [...arr, d.serialNumber]
    }
    return arr
  }, state.devicesbyId)
}

const insertDevices = (state, { devices }) => {
  return (devices || []).reduce((obj, d) => {
    if (!obj[d.serialNumber]) {
      return obj.merge({ [d.serialNumber]: { ...d, loading: false } })
    } else {
      return obj.updateIn([d.serialNumber], mergeState, { ...d })
    }
  }, state.devices)
}

const mergeState = (state, params) => state.merge({ ...params })
/* ------------- Reducers ------------- */

const request = state => state.merge({ loading: true })

const resetRequest = state => state.merge({
  loading: true,
  devicesbyId: [],
  devices: {},
  totalDevices: 0
})

const requestRefresh = state => state.merge({ reloading: true })

const successRefresh = (state, action) => {
  return insertGateways(state, action)
}

const failureRefresh = state => state.merge({ reloading: false })

const success = (state, action) => {
  return insertGateways(state, action)
}

const current = (state, { currentDevice }) => state.merge({ currentDevice })

const requestCount = state => state.merge({ loading: true })

const requestCounter = (state, { data }) => state.setIn(['counters', data.devicetype, 'loading'], true)

const successtCounter = (state, { data }) => state.updateIn(['counters', data.devicetype], mergeState, { all: data.count || 0, loading: false })

const successtCounterOnline = (state, { data }) => state.updateIn(['counters', data.devicetype], mergeState, { connected: data.count || 0, loading: false })

const successtCounterDisconnect = (state, { data }) => state.updateIn(['counters', data.devicetype], mergeState, { disconnected: data.count || 0, loading: false })

const successBattery = (state, { data, params }) => {
  const devices = data && data.dataPoints
    ? data.dataPoints.reduce((arr, d) =>
      [...arr, ...d.deviceArray.map(x => ({ serialNumber: x, batteryLevel: parseInt(d.pointValue, 10), type: params.devicetype }))]
      , [])
    : []
  return state.merge({
    devicesbyId: insertDevicesId(state, { ...devices }),
    devices: insertDevices(state, { ...devices }),
    loading: false
  })
}

const successCycle = (state, { data, params }) => {
  const devices = data && data.dataPoints
    ? data.dataPoints.reduce((arr, d) =>
      [...arr, ...d.deviceArray.map(x => ({ serialNumber: x, cycleCount: parseInt(d.pointValue, 10), type: params.devicetype }))]
      , [])
    : []

  return state.merge({
    devicesbyId: insertDevicesId(state, { devices }),
    devices: insertDevices(state, { devices }),
    loading: false
  })
}

const successAvg = (state, { data, params }) => {
  const devices = data && data.dataPoints
    ? data.dataPoints.reduce((arr, d) => [...arr,
    ...d.deviceArray.map(x => ({ serialNumber: x, avgDrawn: parseInt(d.pointValue, 10), type: params.devicetype }))]
      , [])
    : []

  return state.merge({
    devicesbyId: insertDevicesId(state, { devices }),
    devices: insertDevices(state, { devices }),
    loading: false
  })
}

const requestBatteries = state => state.merge({ loading: true })

const remove = state => state.merge({ devicesbyId: [], devices: {} })
const getTelemetry = (state, { data }) => state.merge({ telemetryData: {}, loading: true })

const insertGateways = (state, { devices }) => {
  const devicesList = devices.devices.reduce((acc, d) => {
    return [
      ...acc,
      {
        deviceConfiguration: d.deviceConfiguration,
        deviceGuid: d.deviceIdentifier.deviceGuid,
        deviceName: d.deviceDetail.deviceName,
        deviceState: d.deviceDetail.deviceState,
        serialNumber: d.deviceIdentifier.serialNumber,
        deviceType: d.deviceIdentifier.deviceType || 'Not Available',
        batterySerialNumber: d.deviceDetail.batterySerialNumber,
        configurationNumber: d.deviceIdentifier.configurationNumber,
        manufacturer: d.deviceIdentifier.deviceManufacturer,
        model: d.deviceDetail.deviceModel || d.deviceIdentifier.model,
        firmware: d.deviceConfiguration.firmwareDetails,
        firmwareVersion: d.deviceConfiguration.firmwareDetails.firmwareVersion,
        availableVersion: get(d, 'deviceConfiguration.firmwareDetails.versionUpdates[0].newVersionNumber', ''),
        osVersion: d.deviceConfiguration.platform.operatingSystem,
        softwareVersion: d.deviceConfiguration.softwareDetails,
        availableSoftware: get(d, 'deviceConfiguration.softwareDetails.versionUpdates[0].newVersionNumber', ''),
        securityPatch: d.deviceConfiguration.securityPatches.securityPatchLevel,
        macAddress: d.deviceIdentifier.macAddress,
		    ipAddress: d.deviceIdentifier.ipAddress,
        status: d.status?.status,
        cellPhoneNumber: d.deviceIdentifier.cellPhoneNumber,
        wifiFrequency: d.deviceIdentifier.wifiFreq,
        ssid: d.deviceIdentifier.ssId,
        deviceDbStatus: d.deviceIdentifier.deviceDbStatus,
        imsi: d.deviceIdentifier.imsi,
        msisdn: d.deviceIdentifier.msisdn,
        networktype: d.deviceIdentifier.networktype,
        networkcarrier: d.deviceIdentifier.networkcarrier,
        lastUpdated: d.status.lastOnline, // Didn't removed this due to being used intensively.
        lastOnline: d.status.lastOnline,
        userName: d.deviceDetail.userName,
        hierarchy: d.deviceDetail.deviceHierarchy,
        displayModel: get(d, 'deviceDetail.deviceDisplayModel', ''),
        photoUrl: d.deviceDetail.devicePhotoUrl,
        deviceConnected: d.deviceConnected.map(de => de.deviceIdentifier.serialNumber),
        systemGuid: d.deviceIdentifier.systemGuid,
        isDeviceAliasReadonly: d.deviceDetail.isDeviceAliasReadonly,
        isAliasSetIncorrectly: d.deviceDetail.isAliasSetIncorrectly,
        deviceDetail: { deviceName: d.deviceDetail.deviceName },
        dropEvents: {
          softDrop: get(d, 'deviceDetail.softImpactEvents', 0) || 0,
          hardDrop: get(d, 'deviceDetail.hardImpactEvents', 0) || 0,
          freeFall: get(d, 'deviceDetail.freeFallEvents', 0) || 0
        },
        originalObject: d
      }
    ]
  }, [])
  return state.merge({
    devicesbyId: insertDevicesId(state, { devices: devicesList }),
    devices: insertDevices(state, { devices: devicesList }),
    loading: false,
    loadingBackground: false,
    reloading: false,
    totalDevices: devices.totalDevices,
    stopLoading: isEmpty(devicesList),
    errors: []
  })
}

export const deviceSiteFilterInsert = (state, { devices }) => {
  const deviceList = []

  if (devices.data.devices) {
    Object.keys(devices.data.devices).forEach((key) => {
      deviceList.push(devices.data.devices[key])
    })
  }

  const devicesList = deviceList.reduce((acc, d) => {
    return [
      ...acc,
      {
        deviceGuid: d.id,
        deviceName: d.name,
        deviceState: d.deviceStatus,
        serialNumber: d.serialNumber,
        deviceType: d.type || 'Not Available',
        isDeviceAliasReadonly: d.isDeviceAliasReadonly,
        isAliasSetIncorrectly: d.isAliasSetIncorrectly
      }
    ]
  }, [])

  return state.merge({
    devicesbyId: insertDevicesId(state, { devices: devicesList }),
    devices: insertDevices(state, { devices: devicesList }),
    loading: false,
    loadingBackground: false,
    reloading: false,
    totalDevices: devices.total ? devices.total : 0,
    stopLoading: isEmpty(devicesList),
    errors: []
  })
}

const telemetrySuccess = (state, { telemetryData }) => state.merge({ telemetryData, loading: false })

const genericSuccess = state => state.merge({ loading: false, error: false, loadingBackground: false })

const genericFailure = state => state.merge({ loading: false, error: true, loadingBackground: false })

const gatewaysError = state => state.merge({
  errors: ['Some error'],
  loading: false,
  loadingBackground: false
})

const clearCurrent = state => state.merge({ currentDevice: '' })

const toggleMultiple = (state, { id }) => {
  const currentDevices = state.getIn(['selectedDevices'])
  const newDevices = currentDevices.indexOf(id) === -1
    ? [...currentDevices, id]
    : currentDevices.filter(x => x !== id)
  const [head] = newDevices.length === 1 ? newDevices : ['']
  return state.merge({ selectedDevices: newDevices, currentDevice: head })
}

const resetMultiple = state => state.merge({ selectedDevices: [] })

const qrSetData = (state, { name, value }) => state.setIn(['qrData', name], value)

const updateSuccess = state =>
  state.merge({ loading: false, error: false, selectedSite: '', selectedDevices: [] })

const updateFailure = state =>
  genericFailure(state)

const selectSite = (state, { siteId }) => state.merge({ selectedSite: siteId })

const uploadProvisionRequest = (state, { data }) => state.merge({ loading: true, errors: [], devicesToProvision: data })

const uploadProvisionRequestSuccess = (state, { data }) => {
  const statusResponse = {}
  let devicesToProvision = [];
  data.data.forEach(({ model, serial, status, message }) => {
    if (model && serial) {
      statusResponse[model + serial] = { status, message }
      devicesToProvision = data.data
    } else {
      statusResponse['modelserial'] = { status, message }
      devicesToProvision = state.getIn(['devicesToProvision']).length > 0 ? state.getIn(['devicesToProvision']) : []
    }
  })
  const updates = devicesToProvision.map(d => {
    return statusResponse[d.model + d.serial] ? { ...d, ...statusResponse[d.model + d.serial] } : { ...d, ...statusResponse["modelserial"] }
  })
  return state.merge({ loading: false, error: [], devicesToProvision: updates })
}

const uploadProvisionRequestFailure = (state, { data }) => {
  const keys = data.reduce((res, { model, serial, message }) => {
    res[model + serial] = { status: 'not provisioned', message }
    return res
  }, {})
  const devicesToProvision = state.getIn(['devicesToProvision'])
  const updates = devicesToProvision.map(d => {
    return keys[d.key] ? { ...d, ...keys[d.key] } : d
  })
  return state.merge({ loading: false, devicesToProvision: updates })
}

const updateBulkEditData = (state, { data }) => state.merge({ devicesBulkEdit: data })

const updateBulkEditDataRequest = (state, { data }) => state.merge({ loading: true })

const updateBulkEditDataSuccess = (state, { data }) =>
  state.merge({
    loading: false,
    devicesBulkEdit: data.map(deviceData => ({ ...deviceData, status: { value: 'Updated', error: '' } }))
  })

const updateBulkEditDataFailure = (state, { data }) => state.merge({ loading: false })

const cleanProvionResults = state => state.merge({ devicesToProvision: [], errors: [] })

const getFamiliesAndModelsSuccess = (state, { data }) => {
  const families = data.reduce((res, curr) => {
    return { ...res, [curr.deviceType]: { label: curr.deviceTypeLabel, models: curr.deviceModels } }
  }, {})
  return state.merge({ families, errors: [] })
}

const getFamiliesAndModelsFailure = state => state.merge({ errors: ['Error getting model list'] })

const getPrinterMediaStatusRequest = state => state.setIn(['events', 'isPrinterOutOfMedia'], null)

const getPrinterMediaStatusSuccess = (state, { data }) => {
  const event = get(data, 'eventList[0].value', null)
  const isOutOfMedia = event ? event === 'OUT' || event === 'OUT OF MEDIA' || event === 'FAULTED' : null
  return stopLoading(state).setIn(['events', 'isPrinterOutOfMedia'], isOutOfMedia)
}

const getPrinterMediaStatusFailure = state => stopLoading(state).setIn(['events', 'isPrinterOutOfMedia'], null)

const getPrinterRibbonStatusRequest = state => state.setIn(['events', 'isPrinterOutOfRibbon'], null)

const getPrinterRibbonStatusSuccess = (state, { data }) => {
  const event = get(data, 'eventList[0].value', null)
  const isOutOfRibbon = event ? event === 'OUT' || event === 'OUT OF RIBBON' : null

  return stopLoading(state).setIn(['events', 'isPrinterOutOfRibbon'], isOutOfRibbon)
}

const getPrinterRibbonStatusFailure = state => stopLoading(state).setIn(['events', 'isPrinterOutOfRibbon'], null)

const stopLoading = (state) => state.merge({ loading: false })

const deviceUpdateAlias = (state, { data }) => state.merge({ sending: true })

const deviceUpdateAliasSuccess = (state, { data }) => state.merge({ initialValue: data.deviceDetails.deviceName })

const deviceUpdateAliasFailure = (state) => state.merge({ sending: false })

const deviceSetRemoteAccess = (state, { selectedRemoteAccessDevice, selectedRemoteAccessDeviceLoading }) => state.merge({ selectedRemoteAccessDevice, selectedRemoteAccessDeviceLoading })

const backgroundRequest = (state, { pageNumber }) => state.merge({
  loadingBackground: true,
  devicesbyId: pageNumber === 1 ? [] : state.getIn(['devicesbyId']),
  devices: pageNumber === 1 ? {} : state.getIn(['devices']),
  totalDevices: pageNumber === 1 ? 0 : state.getIn(['totalDevices'])
})

const backgroundCancel = (state) => state.merge({
  loadingBackground: false
})

const qrRequest = (state) => {
  const qrState = state.getIn(['qrData'], {})

  return state.merge({
    qrData: {
      ...qrState,
      loading: true,
      error: '',
      imageUrl: qrImage,
      imageUrls: []
    }
  })
}

const qrSuccess = (state, { data }) => {
  const qrState = state.getIn(['qrData'], {})

  return state.merge({
    qrData: {
      ...qrState,
      loading: false,
      error: '',
      qrValue: data.responseToken,
      imageUrl: data.responseTokenUrl,
      imageUrls: data.responseTokenUrls
    }
  })
}

const qrFailure = (state, { status }) => {
  const qrState = state.getIn(['qrData'], {})

  return state.merge({
    qrData: {
      ...qrState,
      loading: false,
      error: status === 403 ? 'PrivilegesError' : 'Error',
      qrValue: 'Error',
      imageUrl: qrImage,
      imageUrls: []
    }
  })
}

const qrReset = state => state.merge({
  qrData: {
    Site: '',
    isValid: false,
    acceptedTerms: false,
    loading: false,
    error: '',
    qrValue: '',
    imageUrl: qrImage,
    imageUrls: []
  }
})

const devicesOutOfRangeRequest = (state,{urlParams, pageSize, pageNumber}) => state.merge({ devicesOutOfRange: [], loading: true })

const devicesOutOfRangeSuccess = (state, { data }) => state.merge({
  devicesOutOfRange: data.alerts.map(deviceData => ({ ...deviceData })),
  totalRecommendations: data.total
})

export const deviceSetFilterDevicesById = (state, { filterDevicesById }) => state.merge({ filterDevicesById })

export const deviceRemoveFilterDevicesById = state => state.merge({ filterDevicesById: [] })

export const devicesOutOfRangeGenericResponse = (state) => state.merge({ loading: false })

const devicesOutOfRangeAdditionalRequest = (state,{urlParams, pageSize, pageNumber}) => state.merge({ loading: true })

const devicesOutOfRangeAdditionalSuccess = (state, { data }) => state.update('devicesOutOfRange', (recommendations) => [...recommendations, data.alerts.map(deviceData => ({ ...deviceData })) ])

const devicesOutOfRangeClean = state => state.merge({devicesOutOfRange:[]})
export const setStatusConnection = (state, { status, serial }) => state.updateIn(['devices', `${serial}`, 'status'], () => (status))

export const reducer = createReducer(INITIAL_STATE, {
  [Types.DEVICE_REQUEST]: request,
  [Types.DEVICE_SUCCESS]: success,
  [Types.DEVICE_CURRENT]: current,
  [Types.DEVICE_REQUEST_COUNT]: requestCount,
  [Types.DEVICE_REQUEST_ONLINE]: requestCount,
  [Types.DEVICE_REQUEST_DISCONNECTED]: requestCount,
  [Types.DEVICE_REQUEST_COUNTER]: requestCounter,
  [Types.DEVICE_SUCCESS_COUNTER]: successtCounter,
  [Types.DEVICE_REQUEST_COUNTER_ONLINE]: requestCounter,
  [Types.DEVICE_SUCCESS_COUNTER_ONLINE]: successtCounterOnline,
  [Types.DEVICE_REQUEST_COUNTER_DISCONNECTED]: requestCounter,
  [Types.DEVICE_SUCCESS_COUNTER_DISCONNECTED]: successtCounterDisconnect,
  [Types.DEVICE_REQUEST_BATTERY]: requestCount,
  [Types.DEVICE_SUCCESS_BATTERY]: successBattery,
  [Types.DEVICE_REQUEST_CYCLE]: requestCount,
  [Types.DEVICE_SUCCESS_CYCLE]: successCycle,
  [Types.DEVICE_REQUEST_AVERAGE]: requestCount,
  [Types.DEVICE_SUCCESS_AVERAGE]: successAvg,
  [Types.DEVICE_REMOVE]: remove,
  [Types.DEVICE_REQUEST_GATEWAYS]: request,
  [Types.DEVICE_RESET_REQUEST_GATEWAYS]: resetRequest,
  [Types.DEVICE_SUCCESS_GATEWAYS]: insertGateways,
  [Types.DEVICE_REQUEST_REFRESH]: requestRefresh,
  [Types.DEVICE_REFRESH_SUCCESS]: successRefresh,
  [Types.DEVICE_REFRESH_FAILURE]: failureRefresh,
  [Types.DEVICE_GENERIC_STOP]: genericFailure,
  [Types.DEVICE_FAILURE]: gatewaysError,
  [Types.DEVICE_SET_REQUEST]: current,
  [Types.DEVICE_SET_GATEWAY]: current,
  [Types.DEVICE_GET_TELEMETRY]: getTelemetry,
  [Types.DEVICE_SUCCESS_TELEMETRY]: telemetrySuccess,
  [Types.DEVICE_CLEAR_CURRENT]: clearCurrent,
  [Types.DEVICE_TOGGLE_MULTIPLE]: toggleMultiple,
  [Types.DEVICE_RESET_MULTIPLE]: resetMultiple,
  [Types.DEVICE_SET_QR_DATA]: qrSetData,
  [Types.DEVICE_REQUEST_BATTERIES]: requestBatteries,
  [Types.DEVICE_UPDATE_SUCCESS]: updateSuccess,
  [Types.DEVICE_UPDATE_REQUEST]: request,
  [Types.DEVICE_UPDATE_FAILURE]: updateFailure,
  [Types.DEVICE_SELECT_SITE]: selectSite,
  [Types.DEVICE_UPLOAD_PROVISION_REQUEST]: uploadProvisionRequest,
  [Types.DEVICE_UPLOAD_PROVISION_REQUEST_SUCCESS]: uploadProvisionRequestSuccess,
  [Types.DEVICE_UPLOAD_PROVISION_REQUEST_FAILURE]: uploadProvisionRequestFailure,
  [Types.DEVICE_UPLOAD_PROVISION_CLEAN]: cleanProvionResults,
  [Types.DEVICE_FAMILIES_AND_MODELS_REQUEST_SUCCESS]: getFamiliesAndModelsSuccess,
  [Types.DEVICE_FAMILIES_AND_MODELS_REQUEST_FAILURE]: getFamiliesAndModelsFailure,
  [Types.DEVICE_PRINTER_MEDIA_STATUS_REQUEST]: getPrinterMediaStatusRequest,
  [Types.DEVICE_PRINTER_MEDIA_STATUS_SUCCESS]: getPrinterMediaStatusSuccess,
  [Types.DEVICE_PRINTER_MEDIA_STATUS_FAILURE]: getPrinterMediaStatusFailure,
  [Types.DEVICE_PRINTER_RIBBON_STATUS_REQUEST]: getPrinterRibbonStatusRequest,
  [Types.DEVICE_PRINTER_RIBBON_STATUS_SUCCESS]: getPrinterRibbonStatusSuccess,
  [Types.DEVICE_PRINTER_RIBBON_STATUS_FAILURE]: getPrinterRibbonStatusFailure,
  [Types.DEVICE_UPDATE_ALIAS_REQUEST]: deviceUpdateAlias,
  [Types.DEVICE_UPDATE_ALIAS_SUCCESS]: deviceUpdateAliasSuccess,
  [Types.DEVICE_UPDATE_ALIAS_FAILURE]: deviceUpdateAliasFailure,
  [Types.DEVICE_SET_REMOTE_ACCESS]: deviceSetRemoteAccess,
  [Types.DEVICE_BACKGROUND_REQUEST_ALL]: backgroundRequest,
  [Types.DEVICE_BACKGROUND_CANCEL_ALL]: backgroundCancel,
  [Types.DEVICE_QR_REQUEST]: qrRequest,
  [Types.DEVICE_QR_SUCCESS]: qrSuccess,
  [Types.DEVICE_QR_FAILURE]: qrFailure,
  [Types.DEVICE_QR_RESET]: qrReset,
  [Types.UPDATE_BULK_EDIT_DATA]: updateBulkEditData,
  [Types.UPDATE_BULK_EDIT_DATA_REQUEST]: updateBulkEditDataRequest,
  [Types.UPDATE_BULK_EDIT_DATA_REQUEST_SUCCESS]: updateBulkEditDataSuccess,
  [Types.UPDATE_BULK_EDIT_DATA_REQUEST_FAILURE]: updateBulkEditDataFailure,
  [Types.DEVICE_SET_FILTER_DEVICES_BY_ID]: deviceSetFilterDevicesById,
  [Types.DEVICE_REMOVE_FILTER_DEVICES_BY_ID]: deviceRemoveFilterDevicesById,
  [Types.DEVICE_SITE_FILTER]: resetRequest,
  [Types.DEVICE_SITE_FILTER_SUCCESS]: deviceSiteFilterInsert,
  [Types.DEVICE_SITE_FILTER_FAILURE]: failureRefresh,
  [Types.DEVICES_OUT_OF_RANGE_REQUEST]: devicesOutOfRangeRequest,
  [Types.DEVICES_OUT_OF_RANGE_SUCCESS]: devicesOutOfRangeSuccess,
  [Types.DEVICES_OUT_OF_RANGE_GENERIC_RESPONSE]: devicesOutOfRangeGenericResponse,
  [Types.DEVICES_OUT_OF_RANGE_ADDITIONAL_REQUEST]: devicesOutOfRangeAdditionalRequest,
  [Types.DEVICES_OUT_OF_RANGE_ADDITIONAL_SUCCESS]: devicesOutOfRangeAdditionalSuccess,
  [Types.DEVICES_OUT_OF_RANGE_CLEAN]:devicesOutOfRangeClean,
  [Types.SET_STATUS_CONNECTION]: setStatusConnection,
})
