import axios from 'axios'
import config from 'Config/AppConfig'

const location = require('../Fixtures/DeviceLocation.json')
const history = require('../Fixtures/DeviceLocationHist.json')
// const locations = require('../Fixtures/LocationsSites.json')

const multipartForm = 'multipart/form-data'

const create = (api) => {
  const getFloorPlan = siteId => api.get(`/devicetracking/all_floorplans?siteId=${siteId}`)

  const uploadFloorPlan = (siteId, image) => {
    const formData = new FormData()
    const headers = {
      'Content-Type': multipartForm
    }

    formData.append('siteId', siteId)
    formData.append('title', siteId)
    formData.append('image', image)

    return api.post(`/devicetracking/floorplan`, formData, { headers })
  }

  const getLocation = params => Promise.resolve(location)

  const getHistory = params => Promise.resolve({ ok: true, data: history })

  const getLocations = (orgUnit = '') => api.get(orgUnit ? `/groupmgmt/OrgUnits/${orgUnit}/Sites` : '/groupmgmt/OrgUnits/Sites')

  const getDeviceLocation = (params) => api.get('/locationmgmt/devicelocations', params)

  const locationSuggestion = term => axios.get(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${term}.json?access_token=`
  )

  const openLocationSuggestion = term => axios.get('https://nominatim.openstreetmap.org/search/', { params: { q: term, format: 'json' } })

  const getAddressByCoords = (coords) => axios.get('https://nominatim.openstreetmap.org/reverse', {
    params: {
      ...coords,
      zoom: 18,
      format: 'json'
    }
  })

  const getAddressByCoordsBING = (coords) => axios.get(
    `https://dev.virtualearth.net/REST/v1/Locations/${coords.lat},${coords.lon}?&key=`
  )

  const getAddressByCoordsAzure = (coords) => axios.get(
    `https://atlas.microsoft.com/search/address/reverse/json?api-version=1.0&subscription-key=&language=en-US&query=${coords.lat},${coords.lon}&number=1`
  )

  const createBuilding = building => api.post('/groupmgmt/OrgUnits/Sites', building)
  const updateBuilding = building => api.put('/groupmgmt/OrgUnits/Sites', building)
  const deleteBuilding = (orgUnit, siteGuid) => api.delete(`/groupmgmt/OrgUnits/${orgUnit}/Sites`, {}, {
    data: {
      siteIdentifier: { siteGuid }
    }
  })

  const createZone = (siteId, buildingId, zone) => api.post(`groupmgmt/OrgUnits/${siteId}/Sites/${buildingId}/zones `, zone)

  const updateZone = (siteId, buildingId, zone) => api.put(`groupmgmt/OrgUnits/${siteId}/Sites/${buildingId}/zones `, zone)

  const createFileStorage = meta => api.post('/storagemgmt/files/', meta)

  const uploadFile = (file, id) => {
    const formData = new FormData()
    formData.append('file', file)
    return api.put(`/storagemgmt/files/${id}/data`, formData, {
      headers: {
        'Content-Type': multipartForm
      }
    })
  }

  const uploadConfigFile = ({ file, ...payload }, config = {}) => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('info', JSON.stringify(payload))
    return api.post('/files', formData, {
      ...config,
      headers: {
        'Content-Type': multipartForm
      }
    })
  }

  return {
    getAddressByCoordsBING,
    getLocation,
    getHistory,
    getLocations,
    getDeviceLocation,
    locationSuggestion,
    createBuilding,
    createZone,
    deleteBuilding,
    updateZone,
    openLocationSuggestion,
    createFileStorage,
    uploadFile,
    updateBuilding,
    getAddressByCoords,
    uploadConfigFile,
    getFloorPlan,
    uploadFloorPlan,
    getAddressByCoordsAzure
  }
}

export default {
  create
}
