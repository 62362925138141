import { useCallback, useEffect } from 'react'
import qs from 'qs'
import { useDispatch, useSelector } from 'react-redux'
import { FLUSH } from 'redux-persist'

import { SsoOauthActions } from 'features/sso-oauth2'
import { isValidJsonString } from 'Utils/PropertyHelper'
import get from 'lodash/get'

let onPremVariables = undefined
const onPrem = global.localStorage.getItem('__OP_INTEL__')
if (onPrem !== undefined && isValidJsonString(onPrem))
  onPremVariables = JSON.parse(onPrem)

const redirect_uri = new URL('/oauth-callback', get(onPremVariables, 'url.safeUrl', process.env.REACT_APP_SITE_URL)).toString()
const defaultLoginUrl = get(onPremVariables, 'url.authUrl', process.env.REACT_APP_AUTH_URL)
const defaultLogoutUrl = get(onPremVariables, 'url.logoutUrl', process.env.REACT_APP_LOGOUT_URL)
const crypto = require('crypto')

const getRandomState = () => {
  return [...Array(2)].map(() => crypto.randomBytes(5).toString('hex').substring(0, 10)).join('');
}

export const defaultRedirectParams = {
  loginUrl: defaultLoginUrl,
  appId: 'opintel_web_code',
  logoutUrl: defaultLogoutUrl
}

export function createRedirectSAMLUrl(params) {
  const { loginUrl } = params;
  if (!loginUrl) {
      console.error("SAML Redirection Error: Missing login URL");
      return "/";
  }

  const query = qs.stringify({
      email: params.loginHint,
      domain: params.clientName,
  }, { addQueryPrefix: true });

  return loginUrl + query;
}

export function createRedirectOauthUrl(params) {
  const { loginUrl, appId, loginHint } = params
  const query = qs.stringify({
    client_id: appId,
    redirect_uri,
    response_type: 'code',
    scope: 'openid email profile',
    state: getRandomState(),
    login_hint: loginHint
  }, {
    addQueryPrefix: true
  })

  return loginUrl + query
}

export function useSSOSAML() {
  const dispatch = useDispatch()

  return useCallback((params) => {
    const redirectUri = createRedirectSAMLUrl(params)
    dispatch(SsoOauthActions.setOauthInfo(params))
    dispatch({ type: FLUSH, result: () => { } })
    window.location.replace(redirectUri)
  }, [dispatch])
}

export function useSsoOauth() {
  const dispatch = useDispatch()

  return useCallback((params) => {
    const redirectUri = createRedirectOauthUrl(params)
    dispatch(SsoOauthActions.setOauthInfo(params))
    dispatch({ type: FLUSH, result: () => { } })
    window.location.replace(redirectUri)
  }, [dispatch])
}

export function useRoutePersistor({ authprovider, path }) {
  const dispatch = useDispatch()
  const loggedIn = useSelector(({ login }) => login.loggedIn)

  useEffect(() => {
    if (!loggedIn && authprovider) {
      dispatch(SsoOauthActions.setRedirectRoute(path))
    }
  }, [path, loggedIn, authprovider, dispatch])
}
