import { createReducer } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import config from '../../../../Config/AppConfig'

import {
  setSamlLoginInfo,
} from './actions'

export const INITIAL_STATE = { 
  accessToken: ''
}

const setSamlLoginInfoReducer = (state = INITIAL_STATE, payload) => {
  state.accessToken = payload.jwt
}

const reducer = createReducer(INITIAL_STATE, {
  [setSamlLoginInfo]: setSamlLoginInfoReducer
})

export const samlLoginReducer = persistReducer({
  key: 'samlLogin',
  storage,
  debug: config.debug
}, reducer)
