import { call, put, select, all, delay, fork, cancel, take } from 'redux-saga/effects'
import { decode } from 'jsonwebtoken'
import moment from 'moment'
import { toast } from 'react-toastify'
import React from 'react'
import { ErrorToast } from 'Themes/ScufStyledComponents'
import LoginActions from 'Redux/LoginRedux'
import UserActions from 'Redux/UserRedux'
import ModalActions from 'Redux/ModalRedux'
import { get, toUpper } from 'lodash'
import { history } from 'Components/Navigation'
import { stringify } from 'qs'
import { createStructuredSelector } from 'reselect'
import config from 'Config/AppConfig'

export function * fetchOauthTokenFullfiled (action) {
  yield call(afterLoginDispatchers, { ok: true, data: action.payload }, { firstTimeLogin: true })
}

export function * fetchOauthTokenRejected (action) {
  yield call(afterLoginDispatchers, { ok: false }, { firstTimeLogin: true })
}

export function * completeDirectLogin (action) {
  yield call(afterLoginDispatchers, { ok: true, data: action.payload }, { firstTimeLogin: true })
}

export function * completeSamlLogin (action) {
  yield call(afterLoginDispatchers, { ok: true, data: action.payload }, { firstTimeLogin: true })
}

export function * afterLoginDispatchers (response, action) {
  const { firstTimeLogin } = action
  const { currentLogin } = yield select(state => state.login)
  const redirectRoute = yield select(state => state.ssoOauth.redirectRoute)

  if (response.ok) {
    const decodedTokenPayload = decode(response.data.accessToken)
    const tokenExp = moment.unix(decodedTokenPayload.exp)
    
    if (!response.data.selectedOrganizationId) {
      yield all([
        put(LoginActions.loginSignInError('unauthorized')),
        put(LoginActions.loginSetAccessToken(response.data.accessToken))
      ])
    } else {
      const currentConsent = get(response, 'headers.x-honssoconsent', 'FALSE')
      const requireConsent = currentLogin === 'saml' && toUpper(currentConsent) !== 'TRUE'

      yield all([
        put(LoginActions.loginSignInSuccess({
          ...response.data,
          decodedTokenPayload,
          sessionTimeout: tokenExp.toDate().toString()
        })),
        put(LoginActions.loginSignInConsent(requireConsent, response.data.userName, response.data.accessToken)),
        put(UserActions.userSelectOrganization(response.data.selectedOrganizationId))
      ])

      yield all([
        put(UserActions.userRequest()),
        put(UserActions.userDetailsRequest())
      ])
    }

    if (window && window.aptrinsic) {
      const { nameid, organization } = decodedTokenPayload
      aptrinsic('identify', { id: nameid },{ id: organization })
    }

    if (redirectRoute) {
      history.push(redirectRoute)
    } else {
      history.push('/')
    }

  } else if (response.status === 400) {
    if (!firstTimeLogin) {
      const error = get(response, 'data.errors[0].message', 'Error changing organization')
      yield all([
        call(toast, <ErrorToast message={error} />),
        put(LoginActions.loginSignInError('unauthorized'))
      ])
    } else {
      yield put(LoginActions.loginSignInError('unauthorized'))
    }

    history.push('/')
  } else if (!firstTimeLogin) {
    const error = get(response, 'data.errors[0].message', 'Error changing organization')
    yield all([
      call(toast, <ErrorToast message={error} />),
      yield put(LoginActions.loginSignInError('unknown'))
    ])

    history.push('/')
  } else {
    yield put(LoginActions.loginSignInError('unknown'))
    history.push('/')
  }
}

export function * afterExtendSessionDispatchers (response) {
  if (response.status === 201) {
    const accessToken = response.data.accessToken
    const decodedTokenPayload = decode(response.data.accessToken)
    const tokenExp = moment.unix(decodedTokenPayload.exp)

    const { userName } = yield select(state => state.login)
    const { requireConsent } = yield select(state => state.login)

    yield all([
      put(LoginActions.loginRefreshTokenSuccess({
        accessToken,
        decodedTokenPayload,
        sessionTimeout: tokenExp.toDate().toString()
      })),
      put(LoginActions.loginSignInConsent(requireConsent, userName, accessToken)),
      put(ModalActions.modalClose())
    ])

  }
  else {
    yield put(LoginActions.loginRefreshTokenError('notExtended'))
    yield put(LoginActions.loginRefreshTokenResetStatus())
  }
}

export function * afterAutoExtendSessionDispatchers (response) {
  if (response.status === 201) {
    const accessToken = response.data.accessToken
    const decodedTokenPayload = decode(response.data.accessToken)
    const tokenExp = moment.unix(decodedTokenPayload.exp)

    const { userName } = yield select(state => state.login)
    const { requireConsent } = yield select(state => state.login)

    yield all([
      put(LoginActions.loginRefreshTokenSuccess({
         accessToken,
        decodedTokenPayload,
        sessionTimeout: tokenExp.toDate().toString()
      })),
      put(LoginActions.loginSignInConsent(requireConsent, userName, accessToken))
    ])
  }
  else {
    yield put(LoginActions.loginRefreshTokenError('notExtended'))
    yield put(LoginActions.loginRefreshTokenResetStatus())    
    yield put(ModalActions.modalOpen('sessionExpiration'))
    
  }
}


export function * login (api, action) {
  const { idToken, headerToken, orgId } = action
  const { currentNonce, currentCustomerType, useOpentoken } = yield select(state => state.login)
  const response = yield call(api.login, idToken, currentCustomerType, headerToken, currentNonce, useOpentoken, orgId)
  yield call(afterLoginDispatchers, response, action)
}

export function * extendSession (api) {
  yield put(LoginActions.loginRefreshTokenError(''))
  try {
    const { accessToken, selectedOrganizationId } = yield select(state => state.login)
    const response = yield call(api.renewToken, accessToken, selectedOrganizationId)

    yield call(afterExtendSessionDispatchers, response)

  } catch (e) {
      yield put(LoginActions.loginRefreshTokenError('notExtended'))
      yield put(LoginActions.loginRefreshTokenResetStatus())
  }
}

export function * autoExtendSession (api) {  
  yield put(LoginActions.loginRefreshTokenError(''))
  try {
    const { accessToken, selectedOrganizationId } = yield select(state => state.login)

    const response = yield call(api.renewToken, accessToken, selectedOrganizationId)      
    yield call(afterAutoExtendSessionDispatchers, response)
  } catch (e) {
      yield put(LoginActions.loginRefreshTokenError('notExtended'))
      yield put(LoginActions.loginRefreshTokenResetStatus())      
      yield put(ModalActions.modalOpen('sessionExpiration'))
  }
}

export function * logOut () {
  yield all([
    put(LoginActions.loginReset('')),
    put(ModalActions.modalClose())
  ])
}

export function * logOutAfterError (api) {
  let params
  let header
  const { currentLogin, logoutUrl, redirectUri, idToken, headerToken, accessToken } = yield select(state => state.login)

  switch (currentLogin) {
    case 'forge':
      params = {
        id_token_hint: idToken,
        id_token: idToken,
        post_logout_redirect_uri: redirectUri
      }
      break

    case 'google':
      params = {
        token: headerToken
      }
	  const urlParams = stringify(params, { addQueryPrefix: true })
	  yield put(LoginActions.loginLogoutRequest())
	  history.push('/')
	  return	  

    case 'saml':
      let appId = yield select(state => state.login.appId)
      const appIdArray = appId.split('_')
      if (appIdArray.length > 1) {
        appId = appIdArray[1]
      }
      params = {
        appId: appId
      }
      break

    case 'macys':
      params = {
        id_token_hint: accessToken,
        post_logout_redirect_uri: redirectUri,
        client_id: appId
      }

      header = {
        headers: { Authorization: `Bearer ${headerToken}` }
      }
      break
    
    case 'HONSSOOpenID':
      yield call(logoutSsoSession)
      return

    case 'SAML20':
      header = {
        headers: { Authorization: `Bearer ${accessToken}` }
      }
      yield put(LoginActions.loginLogoutRequest())
      history.push('/')
      return

    default:
      params = {
        id_token_hint: idToken,
        id_token: idToken,
        post_logout_redirect_uri: redirectUri
      }
      break
  }

  if(logoutUrl) {
    const urlParams = stringify(params, { addQueryPrefix: true })
    yield put(LoginActions.loginLogoutRequest())
    window.location.replace(logoutUrl + urlParams)
  } else {
    yield call(logoutSsoSession)
  }
}


const logoutSsoSelector = createStructuredSelector({
  logoutUrl: ({ ssoOauth }) => ssoOauth.logoutUrl,
  accessToken: ({ login }) => login.accessToken
})

function * logoutSsoSession () {
  const { logoutUrl, accessToken } = yield select(logoutSsoSelector)
  const redirect_uri = config.site.safeUrl
  yield put(LoginActions.loginLogoutRequest())
  if(logoutUrl) {
    const urlParams = stringify({
      id_token_hint: accessToken,
      post_logout_redirect_uri: redirect_uri
    }, { addQueryPrefix: true })
    window.location.replace(logoutUrl + urlParams)
  }
}

function * expirationWatcher (msToExpiration) {
  const expirationTask = yield fork(setExpiration, msToExpiration)

  while (yield delay(100)) {
    const action = yield take('LOGIN_LOGOUT_REQUEST')
    if (expirationTask && action) {
      yield cancel(expirationTask)
    }
  }
}

function * setExpiration (msToExpiration) {
  const minutesToShowModal = 10
  yield delay(msToExpiration - (minutesToShowModal * 60 * 1000))
  yield put(ModalActions.modalOpen('sessionExpiration'))

  yield delay(msToExpiration)
  yield put(LoginActions.loginLogoutSentience())
}

export function * closeAllRemoteAccessWindows () {
  if (window.childWindows) {
    for (const { windowObj } of window.childWindows) windowObj.close()
    delete window.childWindows
  }
}
