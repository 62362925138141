import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

const { Types, Creators } = createActions({
  reportUpdateReports: ['reports'],
  reportUpdateResults: ['results'],
  reportUpdateData: ['data'],
  reportUpdateFields: ['data'],
  reportUpdateId: ['id'],
  reportUpdateField: ['sectionName', 'fieldName', 'fieldValue'],
  reportRequestUpdateDataField: ['sectionName', 'fieldName', 'fieldValue'],
  reportUpdateFormField: ['sectionName', 'fieldName', 'fieldValue'],
  reportUpdateFieldOptions: ['fieldFormName', 'fieldFormValue'],
  reportCalculateTco: null,
  reportCalculateHaas: null,
  reportRequestGetId: ['id'],
  reportRequestGetAll: ['reportType'],
  reportRequestSave: ['reportType'],
  reportRequestSubmitDevice: ['device'],
  reportRequestSubmitTerm: ['term'],
  reportGetField: ['fieldName', 'fieldValue'],
  reportSetCurrent: ['deviceId'],
  reportRequestPricingModelUpdate: ['deviceId'],
  termSetCurrent: ['termSolutionId'],
  resetData: null,
  reportLoading: ['text'],
  reportSuccess: ['text'],
  reportRequestUpdateInputs: ['data'],
  getTemplates: null,
  updateTemplates: ['data'],
  getEventReports: ['payload', 'selectedDateRange', 'setLoading'],
  updateEventReports: ['columns', 'data', 'totalRows'],
  getAdditionalEventReports: ['payload', 'selectedDateRange','isExport'],
  getTotalEventReports: [],
  updateAdditionalEventReports: ['columns', 'data', 'totalRows'],
})

export const ReportTypes = Types
export default Creators

const defaultResults = {
  reportInfo: {},
  reportOutputs: {
    deploymentAssumptions: {}
  }
}

const defaultData = {
  reportInfo: {},
  reportInputs: {}
}

export const INITIAL_STATE = Immutable({
  title: 'report',
  id: '',
  type: 'tco',
  text: 'Loading',
  options: {},
  data: {},
  reports: [],
  results: defaultResults,
  device: {},
  term: {},
  loading: false,
  templatesData: [],
  eventReportsColumns: [],
  eventReportsData: [],
  loadEventReport: false,
  eventReportTotalCount: 0
})

function updateFieldAux (state, type, sectionName, fieldName, fieldValue) {
  if (type) {
    if (sectionName === undefined || sectionName === []) {
      return state.setIn(['data', type, fieldName], fieldValue)
    } else {
      return state.setIn(['data', type, ...sectionName, fieldName], fieldValue)
    }
  } else {
    return state.setIn(['data', ...sectionName, fieldName], fieldValue)
  }
}

function updateFieldOptions (state, fieldName, value) {
  return state.setIn(['options', fieldName], value)
}

function updateCurrentDevice (state, deviceId) {
  const device = ((state.options || {}).products || []).find((obj) => (obj || {}).id === deviceId)
  return state.merge({ device })
}

function updateCurrentTerm (state, termSolutionId) {
  const term = ((state.options || {}).terms || []).find((obj) => (obj || {}).id === termSolutionId)
  return state.merge({ term })
}

export const updateField = (state, { sectionName, fieldName, fieldValue }) => updateFieldAux(state, 'exclusive', sectionName, fieldName, fieldValue)

export const updateFormField = (state, { sectionName, fieldName, fieldValue }) => updateFieldAux(state, 'inclusive', sectionName, fieldName, fieldValue)

export const updateDataField = (state, { sectionName, fieldName, fieldValue }) => updateFieldAux(state, null, sectionName, fieldName, fieldValue)

export const reportUpdateReports = (state, { reports }) => state.merge({ reports, loading: false })

export const reportUpdateResults = (state, { results }) => state.merge({ results: results || defaultResults })

export const reportUpdateId = (state, { id }) => state.merge({ id })

export const reportUpdateData = (state, { data }) => {
  return state.merge({ data })
}

export const reportUpdateFields = (state, { data }) => {
  const newData = { ...state.data, 'exclusive': data }
  return state.merge({ data: newData })
}

export const reportUpdateFieldOptions = (state, { fieldFormName, fieldFormValue }) => updateFieldOptions(state, fieldFormName, fieldFormValue)

export const reportGetField = (state, { fieldName, fieldValue }) => updateFieldOptions(state, fieldName, fieldValue)

export const reportSetCurrent = (state, { deviceId }) => updateCurrentDevice(state, deviceId)

export const termSetCurrent = (state, { termSolutionId }) => updateCurrentTerm(state, termSolutionId)

export const reportGet = (state, { id }) => state.merge({ loading: true, id })

export const calculateTco = (state) => state.merge({ loading: false })

export const calculateHaas = (state) => state.merge({ loading: false })

export const loading = (state, { text }) => state.merge({ loading: true, text })

export const success = (state, { text }) => state.merge({ loading: false, text })

export const resetData = (state) => state.merge({ data: defaultData, results: defaultResults })

export const reportUpdateInputs = (state, { data }) => {
  return state.merge({ data })
}

export const getTemplates = (state) => state.merge({ templatesData: [] })

export const updateTemplates = (state, { data }) => state.merge({ templatesData: data })

export const getEventReports = (state) => state.merge({ eventReportsData: [], loadEventReport: true })

export const updateEventReports = (state, { columns, data, totalRows }) => state.merge({ eventReportsColumns: columns, eventReportsData: data, loadEventReport: false, eventReportTotalCount: totalRows })

export const getAdditionalEventReports = (state) => state.merge({ loadEventReport: false })

export const getTotalEventReports = (state) => state.merge({ loadEventReport: false })

export const updateAdditionalEventReports = (state, { columns, data, totalRows }) => state.merge({ eventReportsColumns: columns, eventReportsData: [...state.eventReportsData, ...data], loadEventReport: false, eventReportTotalCount: totalRows })

export const reducer = createReducer(INITIAL_STATE, {
  [Types.REPORT_UPDATE_FIELD_OPTIONS]: reportUpdateFieldOptions,
  [Types.REPORT_UPDATE_FORM_FIELD]: updateFormField,
  [Types.REPORT_UPDATE_FIELD]: updateField,
  [Types.REPORT_REQUEST_UPDATE_DATA_FIELD]: updateDataField,
  [Types.REPORT_UPDATE_RESULTS]: reportUpdateResults,
  [Types.REPORT_UPDATE_REPORTS]: reportUpdateReports,
  [Types.REPORT_UPDATE_ID]: reportUpdateId,
  [Types.REPORT_UPDATE_DATA]: reportUpdateData,
  [Types.REPORT_UPDATE_FIELDS]: reportUpdateFields,
  [Types.REPORT_REQUEST_GET_ID]: reportGet,
  [Types.REPORT_CALCULATE_TCO]: calculateTco,
  [Types.REPORT_CALCULATE_HAAS]: calculateHaas,
  [Types.REPORT_GET_FIELD]: reportGetField,
  [Types.REPORT_SET_CURRENT]: reportSetCurrent,
  [Types.REPORT_REQUEST_PRICING_MODEL_UPDATE]: reportSetCurrent,
  [Types.TERM_SET_CURRENT]: termSetCurrent,
  [Types.REPORT_REQUEST_GET_ALL]: loading,
  [Types.REPORT_REQUEST_SAVE]: loading,
  [Types.REPORT_REQUEST_SUBMIT_DEVICE]: loading,
  [Types.REPORT_REQUEST_SUBMIT_TERM]: loading,
  [Types.REPORT_LOADING]: loading,
  [Types.REPORT_SUCCESS]: success,
  [Types.RESET_DATA]: resetData,
  [Types.REPORT_REQUEST_UPDATE_INPUTS]: reportUpdateInputs,  
  [Types.GET_TEMPLATES]: getTemplates,
  [Types.UPDATE_TEMPLATES]: updateTemplates,
  [Types.GET_EVENT_REPORTS]: getEventReports,
  [Types.UPDATE_EVENT_REPORTS]: updateEventReports,
  [Types.GET_ADDITIONAL_EVENT_REPORTS]: getAdditionalEventReports,
  [Types.GET_TOTAL_EVENT_REPORTS]: getTotalEventReports,
  [Types.UPDATE_ADDITIONAL_EVENT_REPORTS]: updateAdditionalEventReports

})
