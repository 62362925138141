import { createAction } from '@reduxjs/toolkit'
import { decode } from 'jsonwebtoken'

const PREFIX = 'SAML_LOGIN/'

const initialState = {
  jwt: null
}

export const setSamlLoginInfo = createAction(
  PREFIX + 'SET_SAML_INFO',
  directJWT => {
    const decodedToken = decode(directJWT)
    return ({ payload: {accessToken: directJWT, selectedOrganizationId: decodedToken.organization, userName: decodedToken.email} })
  }
)
