import get from 'lodash/get'

import response from './schedule-task-response.json'

const pagination = {
  pageNumber: 1,
  pageSize: 1000
}

const create = (api) => {
  const devicesTotalCount = () => api.get('/devices/totalcount')

  const devicesCount = (params = {
    devicetype: 'mobilecomputer',
    status: 'connected'
  }) => api.get('/devices/count', params)

  const devices = (params = {
    devicetype: 'mobilecomputer',
    status: 'connected'
  }) => api.get('/devices', params)

  const getModels = params => api.get(`/devices/models`)

  const getManufacturers = params => api.get(`/manufacturers`)

  const getDeviceManufacturers = params => api.get(`/devices/manufacturers`);

  const createManufacturers = (data) => api.post('/manufacturers', data)

  const getDevicesByModel = (devicetype, model) => api.get(`/devices`, { devicetype, model })

  const getFirmwareByModel = (devicetype, model) => api.get(`/assets`, { devicetype, devicemodel: model })

  const getAllFirmwareAssets = () => api.get('/assetsbyuser')

  const getAssetsInfo = (deviceList) => api.post('/assets',
    { DeviceType: '',
      Model: '',
      DeviceIds: deviceList
    })

  const gatewaysCount = (deviceType) => api.get('/devices/count', deviceType)

  const getFirmwareVersions = ({ assetId, connection }) => {
    return api.get(`/v2/devices/firmware`, {
      filter: JSON.stringify({
        assetId,
        connectionStatus: connection && { '$in': connection }
      })
    })
  }

  const getTelemetry = (params = {
    pointid: 'battery.health',
    devicetype: 'printer'
  }) => api.get('/devices/telemetrydata', params)

  const getRequests = (params = {
    type: 'provision',
    status: 'Open'
  }) => api.get('/devices/requests', params)

  const getRejections = (params = {
    type: 'provision',
    status: 'Rejected'
  }) => api.get('/devices/requests', params)

  const getApprovals = (params = {
    type: 'provision',
    status: 'Approved'
  }) => api.get('/devices/requests', params)

  const getRegistrationRequests = (params = {
    type: 'registration',
    status: 'Open'
  }) => api.get('/devices/requests', params)

  const getRegistrationRejections = (params = {
    type: 'registration',
    status: 'Rejected'
  }) => api.get('/devices/requests', params)

  const getRegistrationApprovals = (params = {
    type: 'registration',
    status: 'Approved'
  }) => api.get('/devices/requests', params)

  const getGateways = (pageNumber = 1, pageSize = 1000, devicetype = undefined, cancelToken) => api.get('/devices',
    {
      pageNumber,
      pageSize,
      devicetype
    }, {
      cancelToken: get(cancelToken, 'token')
    })

  const getAllGateways = (params) => api.get('/devices', { devicetype: params.devicetype, status: params.status, since: params.since })

  const getTelemetyById = params => api.get(`/devices/${params}/summary`)

  const getEvents = (params, siteId, pageParams = pagination) => api.get(`/dashboard/devices/${params}/events`, { ...pageParams, orgUnitId: siteId })

  const getLicenses = (params, pageParams = pagination) => api.get(`/devices/${params}/licenses`, { ...pageParams })
  //const getApplicationReports = (id, pageParams = pagination) => api.get(`/devices/telemetry/${id}?category=application`, pageParams)
  const getApplicationReports = (id, startDate, endDate) => api.get(`/dashboard/apps-report/${id}?startDate=${startDate || ''}&endDate=${endDate || ''}`)

  const getApplicationReportsCustom = (id, startDate, endDate) => api.get(`/dashboard/apps-report/${id}?startDate=${startDate || ''}&endDate=${endDate || ''}`)

  const updateFirmware = params => api.post('/schedules', params)

  const updateSoftwareFromFile = formData => api.post('schedules/from-file', formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })

  const getDeviceTelemetryData = params => api.get(`/devices/${params}/summary`)

  const getBatteryTelemetryData = (deviceID, batteryParams) => api.get(`/devices/${deviceID}/summary`, batteryParams)

  const getPrinterHeadTelemetry = (deviceID, params) => api.get(`/devices/${deviceID}/summary`, params)

  const getPrinterTelemetry = (deviceID, params) => api.get(`/devices/${deviceID}/summary`, params)

  const getDeviceFullTelemetry = (deviceId, deviceType, category) => api.get(`/devices/telemetry/${deviceId}`, {
    deviceType,
    category
  })

  const getOsVersions = () => api.get('/osversions')

  const getDeviceById = deviceId => api.get(`/devices/${deviceId}`)

  const getGatewayById = deviceId => api.get(`/devices/allgateways`)

  const getBarcodeStatsById = deviceId => api.get(`/devices/${deviceId}/barcodestats`)

  const getScheduledTask = taskId => Promise.resolve({ data: response, ok: true })

  const cancelFirmwareUpdate = masterJobId => api.put(`/schedules/${masterJobId}/cancel-commands`)

  const onboardDevice = (options) => api.post('/DeviceOnBoarding/token', options)

  const onboardDeviceQrSecondVersion = (options) => api.post('/DeviceOnBoarding/token?version=2', options)

  const updateDevices = (data) => api.put('/devices', data)

  const provisionDevice = (data, site) => api.post(`/deviceonboarding/devices/${site}`, data)

  const getFamilies = (bulkProvisioning) => api.get('/devices/types', {
    bulkProvisioning
  })

  const getPrinterMediaStatus = (deviceId, params = {
    category: 'media',
    name: 'Printer.Media.Status',
    pageNumber: 1,
    pageSize: 1
  }) => api.get(`/devices/${deviceId}/events`, params)

  const getPrinterRibbonStatus = (deviceId, params = {
    category: 'media',
    name: 'Printer.Ribbon.Status',
    pageNumber: 1,
    pageSize: 1
  }) => api.get(`/devices/${deviceId}/events`, params)

  const updateDeviceAlias = (data, siteId) => api.put(`/device/aliasName/${siteId}`, data)

  const getAllAssetsV2 = (params = {}) => api.get('/v2/packages', params)

  const deletePackage = (data, packageId) => api.delete(`/v2/packages/${packageId}`)

  const editPackage = (data, packageId) => api.put(`/v2/packages/${packageId}`, data)

  const getPackageById = (packageId) => api.get(`/v2/packages/${packageId}`)

  const startRACommand = (deviceId, cmd) => api.post(`/devices/${deviceId}/device-rv-commands?command=${cmd}`)

  const stopRACommand = deviceId => api.post(`/devices/${deviceId}/device-rv-commands?command=DEVCOMMAND-RVSTOP`)

  const sendRACommand = (deviceId, cmd) => api.post(`/devices/${deviceId}/device-rv-commands?command=${cmd}`)

  const sendRemoteWipeoutCommand = deviceId => api.post(`/devices/${deviceId}/device-rv-commands?command=DEVCOMMAND-FACTORY_RESET`)

  const getDevicesForAssets = (assetId, params) => api.get('/v1/devices', {
    filter: JSON.stringify({ assetId, 'status': 'connected' }),
    ...params
  })

  const updateBulkEditDevices = data => api.put('/v2/devices', data)

  const getDevicesExportCSVFile = (payload, params) => api.post(`/v2/devices/exportfiles`, payload, { params })

  const getDevicesOutOfRange = data => api.get('alerts/recommended', data)

  const updateDeviceState = (data) => api.post('/devices/state', data)

  const updateAssets = (data) => api.put('/devices/update', data)

  const getDevicesbySN = devices => api.get(`/devices?deviceIds=${devices}`)

  const downloadTenantInfo = () => api.get('tenants/info')

  const getDeviceInstalledAssetsData = params => api.get(`/devices/${params}/installedassets`)

  const getDeviceBatteryLevelData = (deviceId, batteryId, starttime, endtime, granularity) => {
    // TEMPORARY UNTIL ENDPOINT EXISTS
    return {
        data: [
        {
          dayTime: "2024-03-03T13:00:00.000Z",
          batteryLevel: 22
        },
        {
          dayTime: "2024-03-03T14:00:00.000Z",
          batteryLevel: 65,
          isCharging: true
        },
        {
          dayTime: "2024-03-03T15:00:00.000Z",
          batteryLevel: 85,
          isCharging: true
        },
        {
          dayTime: "2024-03-03T16:00:00.000Z",
          batteryLevel: 97,
          isCharging: true
        },
        {
          dayTime: "2024-03-03T17:00:00.000Z",
          batteryLevel: 100,
          isCharging: true
        },
        {
          dayTime: "2024-03-03T18:00:00.000Z",
          batteryLevel: 94
        },
        {
          dayTime: "2024-03-03T19:00:00.000Z",
          batteryLevel: 86
        },
        {
          dayTime: "2024-03-03T20:00:00.000Z",
          batteryLevel: 75
        },
        {
          dayTime: "2024-03-03T21:00:00.000Z",
          batteryLevel: 66
        },
        {
          dayTime: "2024-03-03T22:00:00.000Z",
          batteryLevel: 63
        },
        {
          dayTime: "2024-03-03T23:00:00.000Z",
          batteryLevel: 58
        },
        {
          dayTime: "2024-03-04T00:00:00.000Z",
          batteryLevel: 42
        },
        {
          dayTime: "2024-03-04T01:00:00.000Z",
          batteryLevel: 38
        },
        {
          dayTime: "2024-03-04T02:00:00.000Z",
          batteryLevel: 33
        },
        {
          dayTime: "2024-03-04T03:00:00.000Z",
          batteryLevel: 28
        },
        {
          dayTime: "2024-03-04T04:00:00.000Z",
          batteryLevel: 24
        },
        {
          dayTime: "2024-03-04T05:00:00.000Z",
          batteryLevel: 15
        },
        {
          dayTime: "2024-03-04T06:00:00.000Z",
          batteryLevel: 27,
          isCharging: true
        },
        {
          dayTime: "2024-03-04T07:00:00.000Z",
          batteryLevel: 36,
          isCharging: true
        },
        {
          dayTime: "2024-03-04T08:00:00.000Z",
          batteryLevel: 52,
          isCharging: true
        },
        {
          dayTime: "2024-03-04T09:00:00.000Z",
          batteryLevel: 65,
          isCharging: true
        },
        {
          dayTime: "2024-03-04T10:00:00.000Z",
          batteryLevel: 82,
          isCharging: true
        },
        {
          dayTime: "2024-03-04T11:00:00.000Z",
          batteryLevel: 94,
          isCharging: true
        },
        {
          dayTime: "2024-03-04T12:00:00.000Z",
          batteryLevel: 100,
          isCharging: true
        }
      ]
    }
  }
  const getNewAssetTypeVersions = (params) => {
    return api.get(`/swfwmngt/new-version-update?devicetype=${params.device_type}&devicemodel=${params.device_model}&pf_curr_version=${params.pf_curr_version}&sw_curr_version=${params.sw_curr_version}&fw_curr_version=${params.fw_curr_version}`)
  }
  

  return {
    devicesCount,
    updateDeviceAlias,
    devicesTotalCount,
    devices,
    gatewaysCount,
    getDevicesByModel,
    getFirmwareByModel,
    getAllFirmwareAssets,
    getBarcodeStatsById,
    getTelemetry,
    getEvents,
    getLicenses,
    getRequests,
    getRejections,
    getApprovals,
    getRegistrationRequests,
    getRegistrationRejections,
    getRegistrationApprovals,
    getGateways,
    getModels,
    getTelemetyById,
    getDeviceTelemetryData,
    getBatteryTelemetryData,
    getPrinterTelemetry,
    getPrinterHeadTelemetry,
    getAllGateways,
    updateFirmware,
    getAssetsInfo,
    getDeviceFullTelemetry,
    getDeviceById,
    getOsVersions,
    getGatewayById,
    axiosInstance: api.axiosInstance,
    cancelFirmwareUpdate,
    onboardDevice,
    onboardDeviceQrSecondVersion,
    updateDevices,
    provisionDevice,
    getFamilies,
    getPrinterMediaStatus,
    getPrinterRibbonStatus,
    getAllAssetsV2,
    startRACommand,
    stopRACommand,
    sendRACommand,
    sendRemoteWipeoutCommand,
    getApplicationReports,
    getApplicationReportsCustom,
    getDevicesForAssets,
    updateBulkEditDevices,
    updateDeviceState,
    getManufacturers,
    getDeviceManufacturers,
    createManufacturers,
    getDevicesOutOfRange,
    updateAssets,
    getDevicesbySN,
    getFirmwareVersions,
    downloadTenantInfo,
    getScheduledTask,
    deletePackage,
    editPackage,
    getPackageById,
    getDeviceInstalledAssetsData,
    getDevicesExportCSVFile,
    updateSoftwareFromFile,
    getDeviceBatteryLevelData,
    getNewAssetTypeVersions
  }
}

export default {
  create
}
