const downloadEncoders = {
  xml: {
    type: 'application/xml;charset=utf-8',
    encoding: 'UTF-8',
    suffix: '.xml'
  },
  image: {
    type: 'image/png',
    encoding: 'base64',
    suffix: '.png'
  },
  csv: {
    type: 'text/csv;charset=utf-8',
    encoding: 'UTF-8',
    suffix: '.csv'
  },
  json: {
    type: 'application/json;charset=utf-8',
    encoding: 'UTF-8',
    suffix: '.json'
  },
  url: {
    type: null,
    encoding: null,
    suffix: null
  },
  json: {
    type: 'application/json',
    encoding: 'UTF-8',
    suffix: '.json'
  }
}

export function downloadHelper (fileName, fileData, fileType = 'xml', fileSuffix) {
  // NOTE: file suffix must come if fileType is 'url'
  const { type, encoding, suffix } = downloadEncoders[fileType]

  const blob = new Blob([fileData], {
    type,
    encoding
  })

  const _component = document.createElement('a')

  _component.download = `${fileName}${suffix || fileSuffix}`
  _component.href = fileType === 'url'
    ? fileData
    : URL.createObjectURL(blob)
  _component.click()
}


export const downloadCSV = (columns, headers, tableName) => {
        const columnName = headers.map(header => header.content);
        const columnKeys = headers.map(header => header.id);
    
      // Function to escape values according to CSV standard
        const escapeCsvValue = (value) => {
            if (typeof value === 'string') {
                value = value.replace(/"/g, '""');// Escapes double quotes
                if (value.includes(',') || value.includes('\n') || value.includes('"')) {
                    return `"${value}"`; // Wraps in double quotes if necessary
                }
            }
            return value;
        };
    
       // Generate CSV rows
        const csv = Object.values(columns).map(csvContent => {
            return columnKeys.map(key => {
                const value = csvContent[key] ?? '';
                return escapeCsvValue(value.toString()); // Asegurar que sea string
            }).join(',');
        });
    
        // Add headers
        csv.unshift(columnName.map(escapeCsvValue).join(','));
    
        // Download the CSV
        const csvContent = '\uFEFF' + csv.join('\n'); // Add BOM
        downloadHelper(tableName, csvContent, 'csv');
    };