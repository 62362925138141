import CsvFileDrop from "../../../../../CsvFileDrop";
import PropTypes from "prop-types";
import React from "react";
import { LinkText } from "./upload-file.styles";

const UploadFile = ({ onLoadFileSuccess, onClick, onLoadFile, onHideTemplate, onReset }) => {
  return (
    <div>
      <CsvFileDrop
        acceptedTypes={[".csv", ".xlsx"]}
        disabled={false}
        onLoadFile={onLoadFile}
        onLoadFileSuccess={onLoadFileSuccess}
        onReset={onReset}
        height="44.4vh"
      />
      {!onHideTemplate ? (
        <LinkText onClick={onClick}>
          <a> Click </a> to download template file
        </LinkText>
      ) : null}
    </div>
  );
};

UploadFile.displayName = "UploadXlsFile";
UploadFile.propTypes = {
  onLoadFile: PropTypes.func,
  onClick: PropTypes.func,
  onLoadFileSuccess: PropTypes.func,
  onReset: PropTypes.func
};

export default UploadFile;