export class NetworkRangesService {
  constructor(apiClient) {
    this.api = apiClient
  }


  //PUT

  createSingleNetworkRange = (networkRange) => this.api.put(`/groupmgmt/OrgUnits/${networkRange.organizationUnitKey}/networks`, networkRange)

  // GET

  getNetworkRanges = json => this.api.get(`/groupmgmt/OrgUnits/${json}/networks/`)

  //POST BULK

  uploadNetworkRanges = (siteId, sitesFromFile, hierarchy2Site, isOrg) => {
    sitesFromFile = JSON.stringify(sitesFromFile)
    const formData = new FormData()
    formData.append('file', sitesFromFile)
    formData.append('sitesIds', hierarchy2Site)
    return this.api.post(`/groupmgmt/OrgUnits/${siteId}/networks/upload/${isOrg}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
  }

  // POST
  updateNetworkRange = (id, networkRange) => this.api.post(`/groupmgmt/OrgUnits/${networkRange.organizationUnitKey}/networks?network_id=${id}`, networkRange)

  // DELETE

  deleteNetworkRanges = (networkRange, siteId) => {
    const firstNetworkId = networkRange?.length > 0 ? networkRange[0].networkdId : null;
    return this.api.delete(`/groupmgmt/OrgUnits/${siteId}/networks/${firstNetworkId}`, null,
    {
        data: networkRange
    });
}
}
