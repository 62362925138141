import { createStructuredSelector } from 'reselect'

export const any = (...permissions) => permissions.some(permission => !!permission)
export const all = (...permissions) => permissions.every(permission => !!permission)

export const hasDeleteAssets = ({ permissions }) => !!permissions?.allPermissions?.['device:offboard']
export const hasEditState = ({ permissions }) => !!permissions?.allPermissions?.['inventory:edit_state']
export const hasCheckIn = ({ permissions }) => permissions?.allPermissions?.['inventory:check_in'] === false ? false : true
export const hasCheckOut = ({ permissions }) => permissions?.allPermissions?.['inventory:check_out'] === false ? false : true

export const hasUserView = ({ permissions }) => !!permissions?.allPermissions?.['user:read']
export const hasUserCreate = ({ permissions }) => !!permissions?.allPermissions?.['user:create']
export const hasUserEdit = ({ permissions }) => !!permissions?.allPermissions?.['user:edit']

export const hasSiteView = ({ permissions }) => !!permissions?.allPermissions?.['site:read']
export const hasSiteCreate = ({ permissions }) => !!permissions?.allPermissions?.['site:create']
export const hasSiteEdit = ({ permissions }) => !!permissions?.allPermissions?.['site:edit']

export const hasDashboardOrgCreate = ({ permissions }) => !!permissions?.allPermissions?.['dashboard:organization:layout:create']
export const hasDashboardOrgEdit = ({ permissions }) => !!permissions?.allPermissions?.['dashboard:organization:layout:edit']
export const hasDashboardOrgRead = ({ permissions }) => !!permissions?.allPermissions?.['dashboard:organization:layout:read']
export const hasDashboardOrgDelete = ({ permissions }) => !!permissions?.allPermissions?.['dashboard:organization:layout:delete']

export const hasMyDashboardEdit = ({ permissions }) => !!permissions?.allPermissions?.['dashboard:user:layout:edit']

export const hasDashboardView = ({ permissions }) => !!permissions?.allPermissions?.['dashboard:read']

export const hasAnomalyEdit = ({ permissions }) => !!permissions?.allPermissions?.['dashboard:anomaly:edit']

export const hasDeviceView = ({ permissions }) => !!permissions?.allPermissions?.['device:read']
export const hasViewSoftwareUpdates = ({ permissions }) => !!permissions?.allPermissions?.['device:update:view']
export const hasScheduleSoftwareUpdates = ({ permissions }) => !!permissions?.allPermissions?.['device:update:schedule']

export const hasLicenseManagerUpdates = ({ permissions }) => !!permissions?.allPermissions?.['device:update:license']

export const hasViewOnlyAssignedAsset = ({ permissions }) => !!permissions?.allPermissions?.['asset:viewonlyassigned']

export const hasTagCreate = ({ permissions }) => !!permissions?.allPermissions?.['tagging:create']
export const hasTagEdit = ({ permissions }) => !!permissions?.allPermissions?.['tagging:edit']
export const hasTagDelete = ({ permissions }) => !!permissions?.allPermissions?.['tagging:delete']
export const hasTagRead = ({ permissions }) => !!permissions?.allPermissions?.['tagging:read']

export const hasFileCreate = ({ permissions }) => !!permissions?.allPermissions?.['file:create']

export const hasHoneywellUpdate = ({ permissions }) => !!permissions?.allPermissions?.['package:vendor:create']

export const hasPackageCreate = ({ permissions }) => !!permissions?.allPermissions?.['package:create']
export const hasPackageDelete = ({ permissions }) => !!permissions?.allPermissions?.['package:delete']
export const hasPackageEdit = ({ permissions }) => !!permissions?.allPermissions?.['package:edit']
export const hasViewPositioning = ({ permissions }) => !!permissions?.allPermissions?.['positioning:position:get']

export const hasAssetMobileHide = ({ permissions }) => !!permissions?.allPermissions?.['asset:mobile:hidden']
export const hasAssetScannerHide = ({ permissions }) => !!permissions?.allPermissions?.['asset:scanner:hidden']
export const hasAssetPrinterHide = ({ permissions }) => !!permissions?.allPermissions?.['asset:printer:hidden']
export const hasAssetOtherHide = ({ permissions }) => !!permissions?.allPermissions?.['asset:other:hidden']
export const hasAssetRFIDHide = ({ permissions }) => !!permissions?.allPermissions['asset:rfidreader:hidden'] || false
export const hasAssetRobot = ({ permissions }) => !!permissions?.allPermissions?.['asset:robot:view']
export const hasSiteAnalyticsHide = ({ permissions }) => !!permissions?.allPermissions?.['site:analytics:hidden']
export const hasAssetsConfigurationHide = ({ permissions }) => !!permissions?.allPermissions?.['asset:configuration:hidden']
export const hasDevicesOutRangeHide = ({ permissions }) => !!permissions?.allPermissions?.['device:out_range:hidden']

export const hasViewOperationsSupport = ({ permissions }) => !!permissions?.allPermissions?.['operations_support:read']
export const hasEditOperationsSupport = ({ permissions }) => !!permissions?.allPermissions?.['operations_support:edit']
export const hasCreateOperationsSupport = ({ permissions }) => !!permissions?.allPermissions?.['operations_support:create']

export const isGlobalUser = ({ login }) => !!login?.decodedTokenPayload?.globalRole

export const hasAutomationView = ({ permissions }) => !!permissions?.allPermissions?.['rules:read']

export const hasDeviceLogging = ({ permissions }) => !!permissions?.allPermissions?.['device:logging']

export const hasPackageCarSubscribedFeature = ({ user }) => user?.subscription?.features?.includes('packagecar');
export default createStructuredSelector({
  canEditState: hasEditState,
  canDeleteAssets: hasDeleteAssets,
  canCheckIn: hasCheckIn,
  canCheckOut: hasCheckOut,
  canUserView: hasUserView,
  canUserCreate: hasUserCreate,
  canUserEdit: hasUserEdit,
  canSiteView: hasSiteView,
  canSiteCreate: hasSiteCreate,
  canSiteEdit: hasSiteEdit,
  canDashboardOrgCreate: hasDashboardOrgCreate,
  canDashboardOrgEdit: hasDashboardOrgEdit,
  canDashboardOrgRead: hasDashboardOrgRead,
  canDashboardOrgDelete: hasDashboardOrgDelete,
  canMyDashboardEdit: hasMyDashboardEdit,
  canDashboardView: hasDashboardView,
  canAnomalyEdit: hasAnomalyEdit,
  canDeviceView: hasDeviceView,
  canViewSoftwareUpdates: hasViewSoftwareUpdates,
  canScheduleSoftwareUpdates: hasScheduleSoftwareUpdates,
  canImportLicenseUpdates: hasLicenseManagerUpdates,
  canViewOnlyAssignedAsset: hasViewOnlyAssignedAsset,
  canFileCreate: hasFileCreate,
  canPackageCreate: hasPackageCreate,
  canViewPositioning: hasViewPositioning,
  canTagCreate: hasTagCreate,
  canTagEdit: hasTagEdit,
  canTagDelete: hasTagDelete,
  canTagRead: hasTagRead,
  canAssetMobileHide: hasAssetMobileHide,
  canAssetScannerHide: hasAssetScannerHide,
  canAssetPrinterHide: hasAssetPrinterHide,
  canAssetRFIDHide: hasAssetRFIDHide,
  canAssetOtherHide: hasAssetOtherHide,
  canAssetRobot: hasAssetRobot,
  canSiteAnalyticsHide: hasSiteAnalyticsHide,
  canAssetsConfigurationHide: hasAssetsConfigurationHide,
  canDevicesOutRangeHide: hasDevicesOutRangeHide,
  canViewOperationsSupport: hasViewOperationsSupport,
  canEditOperationsSupport: hasEditOperationsSupport,
  canCreateOperationsSupport: hasCreateOperationsSupport,
  canDeviceLogging: hasDeviceLogging,
  canViewAutomations: hasAutomationView,
  hasPackageCarSubscribedFeature: hasPackageCarSubscribedFeature
})
